import React from 'react';
import { useState, useEffect } from 'react';
import artwork from "../../assets/artwork.json";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';


function Gallery() {
  const [artworks, setArtworks] = useState([]);

  useEffect(() => {
  setArtworks(artwork);
  }, []);
 
  return (
     <div className="gallery">
       { artworks.map((art, index) => {
         return (
           <div key={index} className='gallery-item'>
             <div className='gallery-item-url'>
                <a className='gallery-item-url-img' href={art.url}>
                  <img src={art.cover_img} alt={art.title}/>
                </a>
                <div className="gallery-item-url-description">
                  <h3>{art.title}</h3>
                  <small>{art.year} | {art.tech}</small>
                  <p>{art.description}</p>
                  {art.file ? ( <a href={art.file} className="file-icon">
                    <FontAwesomeIcon className='file' icon={faFile} /> {art.title}
                  </a>) : ( <a syle={{ display : "none" }}></a>)}
                </div>
              </div>
           </div>
         );
       })}
     </div>
  );
};


export default Gallery;