import React, { useState, useEffect } from 'react'
import bio from '../../assets/bio.json';
import icon_linkedin from '../../assets/icons/linkedin.svg';
import icon_insta from '../../assets/icons/instagram.svg';
import icon_html from '../../assets/icons/html.svg';
import icon_css from '../../assets/icons/css.svg';
import icon_js from '../../assets/icons/js.svg';
import icon_react from '../../assets/icons/react.svg';
import icon_angular from '../../assets/icons/angular.svg';
import icon_php from '../../assets/icons/php.svg';
import icon_drupal from '../../assets/icons/drupal.svg';



function About() {
  const [info, setInfo] = useState([]);

  useEffect(() => {
  setInfo(bio);
  }, []);
 
  return (
    <div className="about">
      { info.map((item, index) => {
        return (
          <div key={index} className="about-item">
            <h2>About</h2>
            <div className='about-item-contents'>
              <div className="about-item-contents-bio">
                <h1>{item.name}</h1>
                <div className="about-occupation"><small>{item.current_occupation}</small></div>
                <div className='about-description'>
                    <p>Hello, I am {item.name} and I am currently employed by {item.current_employment} in the Department of {item.department}. I started as a Graphic Designer, and after a short time of self education and obtaining various certifications, I was able to work my way into a {item.current_occupation} position. These days I serve as the foremost developer for the digital signage side of the Digital Signage and Strategy team. When I am not handling my digital signage duties I am aiding the web team with the various websites that they manage. 
                    <br/>
                    <br/>
                    I have had the pleasure of being included in Georgia Institute of Technology's recent visual brand refresh. Being on the visual brand refresh team gave me the opportunity to collaborate with the IC webteam and improve upon as well as add to GT's internal content management system, Mercury, as the lead user interface designer.
                    </p>
                </div>
              </div>
              <div className="about-item-contents-details">
                  <div className="icons">
                    <h4>Skills</h4>
                    <div className="icon-content">
                      <img src={icon_html} />
                      <img src={icon_css} />
                      <img src={icon_js} />
                      <img src={icon_php} />
                      <img src={icon_react} />
                      <img src={icon_angular} />
                      <img src={icon_drupal} />
                    </div>
                  </div>
                  <div className="icons">
                    {/* <h4> Download my resum</h4> */}
                    <div className="icon-content">
                      <a href={item.file}>Click here to view my resume!</a>
                    </div>
                  </div>
                 
              </div>
            </div>
            <div className='about-img'>
              <img />
            </div>
          </div>
          
        )
      }
     )}
    </div>
  )
}

export default About