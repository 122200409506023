import React from 'react'

function Contact() {
  return (
    <div className='contact'>
      <div className='contact-info'>
          <h1>I am available!</h1>
          <p>Do you need a new website, a brand refresh, or consulation? 
            <br></br>
            <br></br>
            You can contact me by email at nicksdesigns3@gmail.com or you can fill out the form and I will get back to you as soon as I can. 
            <br></br>
            <br></br>
            Serious inquires only.
          </p>
      </div>
        <div className="contact-form">
          <form action="../../assets/php/form.php">
            <label for="firstname"> First Name </label>
            <input type="text" id="firstname" name="first" placeholder='Please enter your first name...' />
            <label for="lastname"> Last Name </label>
            <input type="text" id="lastname" name="last" placeholder='Please enter your last name...' />
            <label for="email">Email</label>
            <input type="text" id="email" name="email" placeholder="Enter the email address that you'd like to be contact at..." />
            <label for="message">Inquires</label>
            <textarea type="textarea" id="message" name="message" placeholder="Enter your..." />

            <input type="submit" value="Submit" />
          </form>
        </div>
    </div>
  )
}

export default Contact