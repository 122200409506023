import React from 'react';
import { Link } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBars, faX } from '@fortawesome/free-solid-svg-icons';
import icon_linkedin from '../../assets/icons/linkedin.svg';
import icon_insta from '../../assets/icons/instagram.svg';


function Navigation() {
   function openMenu() {
      let nbl = document.getElementsByClassName('navigation-block-list');
      let bars = document.getElementsByClassName('bars');
      let xmark =  document.getElementsByClassName('x-mark');
      nbl[0].classList.toggle('active');
      bars[0].classList.toggle('inactive');
      xmark[0].classList.toggle('inactive');
    }


  return (
   <>
      <nav className="navigation-block">
         <div className="navigation-block-section">
            <div className="navigation-block-logo">Nicholas Baker</div>
            <div className='navigation-block-menu'>
               <div className="navigation-block-menu-icon" onClick={openMenu}>
                  <FontAwesomeIcon className='bars' icon={faBars} />
                  <FontAwesomeIcon className='inactive x-mark' icon={faX}/>
               </div>
            </div>
         </div>
         <ul className='navigation-block-list'>
            <li>
               <Link to="/">Home</Link>
            </li>
            <li>
               <Link to="/about">About</Link>
            </li>
            {/* <li>
               <Link to="/contact">Contact</Link>
            </li> */}
            <li>
               <a href="https://www.linkedin.com/feed/?trk=homepage-basic_sign-in-submit">LinkedIn</a>
               {/* <a href="https://www.linkedin.com/feed/?trk=homepage-basic_sign-in-submit"><img src={icon_linkedin} /></a> */}
               {/* <img src={icon_insta} /> */}
            </li>
         </ul>
      </nav>
   </>
 

  )
}

export default Navigation