import React from 'react';
import Gallery from '../gallery/Gallery';
import Contact from '../contact/Contact';
function Home() {
  return (
    <div className="home">
      <div className="home-landing">
        <div className='home-landing-shape'></div>
        <div className='home-landing-shape-1'></div>
        <div className="home-landing-text">
          <h1>Nick's Portfolio</h1>
          <p>I am a web developer and designer and have spent many years honing my skills. Please checkout my work below.</p>
        </div>
      </div>
      <div>
        <Gallery />
      </div>

      <div><Contact /></div>

    </div>
  )
}

export default Home